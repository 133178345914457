@import "~bulma";

html,
body {
  font-size: 12px;

  --bulma-body-family: "Spezia SemiMono";
}

.modal {
  --bulma-modal-content-width: 60vw !important;
}

.navbar-item {
  svg {
    width: 130px;
  }
}

.navbar {
  button.navbar-burger,
  button.navbar-item {
    background-color: transparent;
    border: none;
    color: inherit;
    font-size: inherit;

    &:focus {
      outline: none;
    }
  }
}

span.icon + a {
  margin-left: 2px;
}

textarea {
  resize: vertical;
}

input[type="checkbox"] + span {
  margin-left: 4px;
}

.is-floating {
  position: sticky;
  top: 18px;
}

.modal-card-foot {
  justify-content: flex-end;
}

th {
  &.col-date {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
  }

  &.col-amount {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
  }

  &.col-number {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
  }

  &.col-key {
    width: 90px;
    min-width: 90px;
    max-width: 90px;
  }

  &.col-status {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
  }

  &.col-customer {
    min-width: 200px;
  }

  &.col-destination {
    min-width: 200px;
  }

  &.col-name,
  &.col-country {
    min-width: 120px;
  }

  &.col-date-ext {
    width: 130px;
    min-width: 130px;
    max-width: 130px;
  }

  &.col-trip {
    min-width: 300px;
  }

  &.col-description {
    width: 300px;
    min-width: 300px;
    max-width: 300px;
  }
}

.icon-text.key {
  justify-content: space-between;
  align-items: center;
}

button.image {
  border: none;
}

.table tr.is-selected {
  background-color: var(--bulma-warning-95);
}

.is-fullheight {
  height: 100%;
  min-height: 300px;
}

dt {
  font-weight: bold;
}

.legal-document__content {
  li p:last-of-type {
    margin-bottom: 0;
  }

  li {
    &:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }
  }

  ul ul {
    margin-top: 0;
  }
}

tr.is-group-end {
  border-bottom: 2px solid var(--bulma-grey-light);
}

tr.is-muted {
  opacity: 0.5;
}
